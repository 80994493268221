import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "group-travel-assistant"
    }}>{`Group Travel Assistant`}</h1>
    <p>{`The group travel assistant is an interface used to enable customers to edit their group travel orders created by customer support or other instances that are able to create orders on behalf of others.`}</p>
    <h2 {...{
      "id": "create-a-edit-link-for-group-travel"
    }}>{`Create a edit link for group travel`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/pages-sales-guides#create-order"
          }}>{`Create an order`}</a>{`. This must consist of at least ten passengers, that is for now ten order lines.
Remember to attach order notes with deadline for change and contact information to correct customer service office to the order.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create obfuscated url for customer to access a page to view and edit a group-travel order. Call `}<inlineCode parentName="p">{`POST /v1/group-travel-orders/{orderId}`}</inlineCode>{`.`}</p>
        <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>
        {`
            {
                "url": "https://minreise.dev.entur.no/685fe9a1-c1b1-4735-98de-c074dfa99d6d",
            }
       `}
       </pre>
        </ExpandablePanel>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Send the link to the customer.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "deadline"
    }}>{`Deadline`}</h2>
    <p>{`The link will restrict possibility to change the order after the deadline has passed. Default deadline is set to 15 days before earliest departure if not provided in an order note.`}</p>
    <h2 {...{
      "id": "restrictions"
    }}>{`Restrictions`}</h2>
    <p>{`Currently unsupported orders:`}</p>
    <ul>
      <li parentName="ul">{`journey with compulsory seat reservation and multiple user profiles`}</li>
      <li parentName="ul">{`group products`}</li>
      <li parentName="ul">{`without customer contact`}</li>
    </ul>
    <h1 {...{
      "id": "group-travel-assistant-api-reference"
    }}>{`Group Travel Assistant API Reference`}</h1>
    <p><a parentName="p" {...{
        "href": "http://petstore.swagger.io/?url=https://api.entur.io/api-docs/group-travel-assistant"
      }}>{`See the API reference on the Swagger Petstore`}</a></p>
    <Swagger url="https://api.entur.io/api-docs/group-travel-assistant" baseUrl="https://api.entur.io/sales" mdxType="Swagger" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      